import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router"
import Link from "./link";
import { Sun, Moon } from "./icons";

const ThemeSwitchButton = ({ defaultDarkMode }) => {
  const [darkMode, setDarkMode] = useState(_ => {
    // if darkmode is in localstorage, use its value, true or false
    const userPreference = typeof window !== 'undefined' && window.localStorage.getItem("darkMode")
    if (userPreference) {
      return userPreference === "true" // localstorage stringifies booleans
    } else {
      return defaultDarkMode
    }
  })

  // Doc class follows react state
  useEffect(_ => {
    document.body.className = darkMode ? "dark-mode" : ""
  }, [darkMode])

  const handleThemeSwitchButton = e => {
    e.preventDefault()
    const userSetTheme = !darkMode

    setDarkMode(userSetTheme)
    localStorage.setItem("darkMode", userSetTheme)
  }

  return (
    <li className="switch-theme">
      <div className="switch-button" onClick={handleThemeSwitchButton} >
        <div title="Switch to Dark Mode" className={darkMode ? "active" : ""} >
          <Sun />
        </div>
        <div title="Switch to Light Mode" className={darkMode ? "" : "active"} >
          <Moon />
        </div>
      </div>
    </li>
  )
}

export default function NavigationLinks() {
  const data = useStaticQuery(graphql`
        query NavbarLinkQuery {
            site {
                siteMetadata {
                    navLinks {
                        name
                        url
                    }
                    defaultDarkMode
                    switchTheme
                }
            }
        }
    `);
  const currentLocation = useLocation()
  const items = data.site.siteMetadata.navLinks;

  return (
    <ul className="navbar-links">
      {items.map(nl => (
        <li key={nl.name}>
          {nl.url[0] === "/"
            ? <Link to={nl.url} title={nl.name} className={`/${currentLocation.pathname.split("/")[1]}/` === nl.url ? "active" : ""}><span>{nl.name}</span> </Link>
            : <a href={nl.url} target="_blank" rel="noreferrer nofollow"> {nl.name} </a>}
        </li>
      ))}
      {data.site.siteMetadata.switchTheme && (
        <ThemeSwitchButton
          key="themeswitcher"
          defaultDarkMode={data.site.siteMetadata.defaultDarkMode}
        />
      )}
    </ul>
  );
}
